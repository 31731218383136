import React, { useState, useRef } from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const ReferralForm = ({ index, handleRemove }) => {
	return (
		<div className="o-row-2p5 js-referral-row">
			<div className="c-referral-inputs">
				<input type="text" className="c-referral-inputs__input js-referral-name" placeholder="Name" required />
				<input type="email" className="c-referral-inputs__input js-referral-email" placeholder="Email" required />
			</div>
			<button type="button" className="c-referral-remove" onClick={() => handleRemove(index)}>
				Remove
			</button>
		</div>
	);
};

const Referral = ({ location }) => {
	const [formState, setFormState] = useState('');
	const [rows, setRows] = useState([]);
	const firstRowName = useRef(null);
	const firstRowEmail = useRef(null);

	const handleRemoveRow = (index) => {
		setRows(
			rows.filter(function (ele) {
				return ele !== index;
			})
		);
	};

	const handleAddRow = () => {
		setRows([...rows, rows.length]);
	};

	const resetForm = () => {
		setRows([]);
		firstRowName.current.value = '';
		firstRowEmail.current.value = '';
	};

	const generateGUID = () => {
		const fourChars = function () {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		};

		const eightChars = function () {
			return fourChars() + fourChars();
		};

		const twelveChars = function () {
			return fourChars() + fourChars() + fourChars();
		};

		return eightChars() + '-' + fourChars() + '-' + fourChars() + '-' + fourChars() + '-' + twelveChars();
	};

	const submitLeadToLeadHandler = (referral) => {
		const name = referral.name;
		const email = referral.email;
		const params = new URLSearchParams(location.search);
		const referredByGid = params.get('LeadCustomerGID');

		if (!name || !email || !referredByGid) {
			setFormState('error');
			console.error('[Referral] Missing required fields');
			return;
		}

		const lead = Object.assign({}, window.sessionLayer.leadHandler.lead.lead);

		// add lead data
		lead.LastName = name;
		lead.Email = email;
		lead.Lead_URL__c = document.location.href;
		lead.Referrer_GID__c = referredByGid;
		lead.Marketing_Partner__c = 'referral-program';

		// generate new guids so leads dont use the same
		lead.GID__c = '001.' + generateGUID();
		lead.Lead_ID__c = 'USA-' + generateGUID();
		lead.Visitor_Id__c = generateGUID();

		lead.Dynamic_Marketing_Comment__c = JSON.stringify(lead);

		window.sessionLayer.leadHandler
			.submitToLeadHandler(lead)
			.then(function (response) {
				if (response.status && response.status >= 400) {
					setFormState('error');

					if (response.statusText) {
						console.error(response.statusText);
					}

					return;
				}

				setFormState('success');
				console.log(lead.GID__c + ' succesfully submitted to lead handler');
				resetForm();
			})
			.catch(function (error) {
				setFormState('error');
				console.error(error);
			});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const fakeDelay = 1500;
		setFormState('processing');

		setTimeout(function () {
			const rowsArray = Array.prototype.slice.call(document.querySelectorAll('.js-referral-row'));
			const referrals = rowsArray.map(function (row) {
				return {
					name: row.querySelector('.js-referral-name').value,
					email: row.querySelector('.js-referral-email').value
				};
			});

			referrals.forEach(function (referral) {
				submitLeadToLeadHandler(referral);
			});
		}, fakeDelay);
	};

	return (
		<Layout>
			<Seo
				title="Refer A Friend - hear.com"
				description="Everyone deserves to hear well. Refer your friends and they will also receive a $300 discount on their hearing aids."
			/>
			<section className="c-referral-hero">
				{/* mobile and tablet image */}
				<picture>
					<source
						media="(max-width: 748px)"
						srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/referral/hero-mobile.jpg"
					/>
					<source
						media="(max-width: 1025px)"
						srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/referral/hero-desktop-alt.jpg"
					/>
					<img
						className="o-fluid-img u-desktop-hidden"
						srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/referral/hero-desktop-alt.jpg"
						alt="woman smiling"
						loading="lazy"
					/>
				</picture>
				<div className="o-wrapper">
					<div className="c-referral-hero__grid">
						<div className="c-referral-hero__col c-referral-hero__col--img">
							{/* desktop image */}
							<picture>
								<source
									media="(min-width: 1025px)"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/referral/hero.png"
								/>
								<img
									className="o-fluid-img u-mobile-hidden"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/referral/hero.png"
									alt="woman smiling"
									loading="lazy"
								/>
							</picture>
						</div>
						<div className="c-referral-hero__col c-referral-hero__col--content">
							<div className="c-referral-intro">
								<div className="o-row-2p5">
									<h1 className="c-referral-intro__title">
										Earn
										<br />
										<span className="c-referral-intro__title-money">300</span>
										<br />
										By Referring Your Friends
									</h1>
								</div>
								<div className="o-row-2p5">
									<hr className="c-referral-intro__divider" />
								</div>
								<p className="c-referral-intro__copy">
									Your friends will also receive a $300 discount on their hearing aids.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="c-referral-hero__bottom">
					<div className="c-referral-slant">
						<div className="c-referral-slant__skew">
							<div className="c-referral-slant__shape-yellow"></div>
						</div>
					</div>
				</div>
			</section>
			<section className="o-wrapper o-wrapper--small">
				<h2 className="c-referral-title">Add your friend's information here.</h2>
				<div className="o-row-2p5">
					<p className="c-referral-instructions">Refer as many people as you like.</p>
				</div>
				<form className={`c-referral-form ${formState ? `is-${formState}` : ''}`} onSubmit={handleSubmit}>
					<div className="o-row-2p5 js-referral-row">
						<div className="c-referral-inputs">
							<input
								type="text"
								className="c-referral-inputs__input js-referral-name"
								placeholder="Name"
								required
								ref={firstRowName}
							/>
							<input
								type="email"
								className="c-referral-inputs__input js-referral-email"
								placeholder="Email"
								required
								ref={firstRowEmail}
							/>
						</div>
					</div>
					{rows.map((ele) => {
						return <ReferralForm key={ele} index={ele} handleRemove={handleRemoveRow} />;
					})}
					<div className="c-referral-add-grid">
						<button type="button" className="c-referral-add-row track-cta-add-referral" onClick={handleAddRow}>
							<span className="c-referral-add-row__ico">
								<img
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/referral/add-new.svg"
									alt=""
									loading="lazy"
								/>
							</span>
							<p className="c-referral-add-row__copy">Add referral</p>
						</button>
						<button type="submit" className="c-referral-form__submit track-cta-submit-referral">
							<span className="c-referral-form__submit-copy">Submit</span>
							<div className="c-referral-form__submit-loader"></div>
						</button>
					</div>
					<p className="c-referral-form__success">
						<span className="c-referral-form__success-check"></span>
						Thank you for the referral, you'll be hearing from us soon.
					</p>
					<p className="c-referral-form__error">
						<span className="c-referral-form__error-icon"></span>
						There was an issue submitting the form, please try again later.
					</p>
				</form>
			</section>
			<section className="c-referral-steps-section">
				<div className="o-wrapper o-wrapper--small">
					<div className="c-referral-circles">
						<div className="c-referral-circles__col">
							<div className="c-referral-circle">
								<img
									className="c-referral-circle__ico"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/referral/mail.svg"
									alt=""
									loading="lazy"
								/>
								<p className="c-referral-circle__copy">
									Your friend will soon receive an email and a brief assessment to complete.
								</p>
							</div>
						</div>
						<div className="c-referral-circles__col">
							<div className="c-referral-circle">
								<img
									className="c-referral-circle__ico phone"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/referral/phone.svg"
									alt=""
									loading="lazy"
								/>
								<p className="c-referral-circle__copy">
									We'll call and guide your friend to solve for their hearing needs.
								</p>
							</div>
						</div>
						<div className="c-referral-circles__col">
							<div className="c-referral-circle">
								<img
									className="c-referral-circle__ico"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/referral/money.svg"
									alt=""
									loading="lazy"
								/>
								<p className="c-referral-circle__copy">
									A $300 gift card will be sent to you as soon as your friend purchases their hearing aids.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Referral;
